

















import { Component, Vue } from 'vue-property-decorator';
import Product from '@/components/Products/Product.vue';
import ModalEditProduct from '@/components/Products/ModalEditProduct.vue';

import ProductModel from '@/models/Product.model';
import ProductsRepository from '@/repository/modules/products.repository';

@Component({
  components: {
    Product,
    ModalEditProduct,
  },
})
export default class ListProducts extends Vue {
  listProducts!: ProductModel[];
  totalProducts = 0;
  isLoading = true;
  showCreateNewProduct = false;

  async mounted() {
    await this.getListProducts();
  }

  async getListProducts() {
    this.isLoading = true;
    const { listProducts } = await ProductsRepository.getAllProducts();
    this.listProducts = listProducts;
    this.totalProducts = this.listProducts.length;
    this.isLoading = false;
  }
}
