

















import { Component, Vue } from 'vue-property-decorator';
@Component({
  props: {
    showAlertDeleteProduct: {
      required: true,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: null,
    },
  },
})
export default class ModalDeleteProduct extends Vue {}
