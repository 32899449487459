import API from '../repository';
import ProductModel, { ProductModelDTO } from '@/models/Product.model';
import Api from '../repository';

const RESOURCE = '/products';

export type createNewProduct = {
  name: string;
  price: number;
  quantity: number;
  currency: string;
  sign: string;
  country: string;
};

export default {
  async getAllProducts(): Promise<{ listProducts: ProductModel[] }> {
    const { products }: { products: ProductModelDTO[] } = await API.get(`${RESOURCE}`);
    const listProducts = products.map((productDto: ProductModelDTO) => new ProductModel(productDto));
    return {
      listProducts,
    };
  },

  async createProduct(newProduct: createNewProduct): Promise<{ product: ProductModel }> {
    const { product }: { product: ProductModel } = await API.post(`${RESOURCE}`, {
      ...newProduct,
    });
    return {
      product,
    };
  },
  async updateProduct(product_id: string, newProduct: createNewProduct): Promise<{ product: ProductModel }> {
    const { product }: { product: ProductModel } = await API.put(`${RESOURCE}/${product_id}`, {
      ...newProduct,
    });
    return {
      product,
    };
  },

  async deleteProduct(product_id: string): Promise<boolean> {
    const { deleted }: { deleted: boolean } = await Api.delete(`${RESOURCE}/${product_id}`);
    return deleted;
  },
};
