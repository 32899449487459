






































import { Component, Vue, Prop } from 'vue-property-decorator';
import ProductModel from '@/models/Product.model';
import ProductsRepository from '@/repository/modules/products.repository';

import ModalDeleteProduct from './ModalDeleteProduct.vue';
import ModalEditProduct from './ModalEditProduct.vue';

@Component({
  components: {
    ModalDeleteProduct,
    ModalEditProduct,
  },
})
export default class Product extends Vue {
  @Prop({ required: true, type: ProductModel })
  Product!: ProductModel;

  showModalDelete = false;
  isDeleting = false;
  errorsDelete = null;

  showEditProduct = false;

  async deleteThisProduct() {
    this.isDeleting = true;
    try {
      await ProductsRepository.deleteProduct(this.Product._id);
      this.isDeleting = false;
      this.showModalDelete = false;
      this.$emit('RELOAD');
    } catch (e) {
      this.errorsDelete = e.response.data;
      this.isDeleting = false;
    }
  }
}
