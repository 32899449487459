export interface ProductModelImpl {
  _id: string;
  name: string | null;
  quantity: number | null;
  price: number | null;
  currency: string | null;
  sign: string | null;
  country: string | null;
}

export class ProductModelDTO implements ProductModelImpl {
  _id = '';
  name = '';
  quantity = 0;
  price = 0;
  currency = '';
  sign = '';
  country = '';
}

export default class ProductModel extends ProductModelDTO {
  constructor(dto: ProductModelDTO) {
    super();
    Object.assign(this, dto);
  }

  getCountry = () => (this.country ? this.country : 'WW');

  getprice = () => `${this.generatePriceFormat((this.price / 100).toFixed(2))} ${this.currency}`;

  private generatePriceFormat = (amount: string) => amount.replace(/\d(?=(\d{3})+\.)/g, '$&,');
}
