










































import { Component, Vue, Prop } from 'vue-property-decorator';
import ProductsRepository, { createNewProduct } from '@/repository/modules/products.repository';
import ProductModel from '@/models/Product.model';

interface SignsImpl {
  [key: string]: string;
}

@Component({
  props: {
    showEditorProduct: {
      required: true,
      default: false,
    },
    Product: {
      type: ProductModel,
      default: null,
    },
  },
})
export default class ModalEditProduct extends Vue {
  @Prop({ type: ProductModel, default: null })
  Product!: ProductModel;

  isLoading = false;
  form = {
    product_name: '',
    product_country: '',
    product_currency: '',
    product_price: 0,
    product_quantity: 1,
  };

  mounted() {
    if (this.Product) {
      this.form = {
        product_name: this.Product.name,
        product_country: this.Product.country,
        product_currency: this.Product.currency,
        product_quantity: this.Product.quantity,
        product_price: this.Product.price,
      };
    }
  }

  get IsValidForm() {
    if (this.form.product_name == '') return false;
    if (this.form.product_country == '') return false;
    if (this.form.product_currency == '') return false;
    if (this.form.product_price <= 0) return false;
    if (this.form.product_quantity <= 0) return false;

    return true;
  }

  sings: SignsImpl = { EUR: '€', USD: '$', GBP: '£' };

  async createProduct() {
    this.isLoading = true;
    const objProduct = this.createObject();
    try {
      if (this.Product) {
        await ProductsRepository.updateProduct(this.Product._id, objProduct);
      } else {
        await ProductsRepository.createProduct(objProduct);
      }
      this.isLoading = false;
      this.$emit('RELOAD');
      this.$emit('BACK');
      this.cleanForm();
    } catch (e) {
      this.isLoading = false;
    }
  }

  cleanForm() {
    this.form.product_name = '';
    this.form.product_country = '';
    this.form.product_currency = '';
    this.form.product_price = 0;
    this.form.product_quantity = 0;
  }

  createObject(): createNewProduct {
    return {
      name: this.form.product_name,
      price: this.forceNumberType(this.form.product_price),
      quantity: this.forceNumberType(this.form.product_quantity),
      currency: this.form.product_currency,
      sign: this.sings[this.form.product_currency],
      country: this.form.product_country,
    };
  }

  setterPriceHint(price: number) {
    if (price == 0) return '1.00';
    return (price / 100).toFixed(2);
  }

  forceNumberType = (n: number): number => parseInt((n as unknown) as string);
}
